/**
 * Settings reducer
 */

import { createAction, handleActions } from 'redux-actions';

// ------------------------------------
// Constants
// ------------------------------------

export const PRINT_SETTINGS_SAVE = 'PRINT_SETTINGS_SAVE';
export const GENERAL_SETTINGS_SAVE = 'GENERAL_SETTINGS_SAVE';
export const WEIGHT_LABEL_SETTINGS_SAVE = 'WEIGHT_LABEL_SETTINGS_SAVE';

// ------------------------------------
// Actions
// ------------------------------------
const savePrintSettingsAction = createAction(PRINT_SETTINGS_SAVE);
const saveGeneralSettingsAction = createAction(GENERAL_SETTINGS_SAVE);
const saveWeightLabelSettings = createAction(WEIGHT_LABEL_SETTINGS_SAVE)

export const actions = {
  savePrintSettingsAction,
  saveGeneralSettingsAction,
  saveWeightLabelSettings
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  printSettings: null,
  generalSettings: {
    allowManualReceipts: false,
    quickPurchase: false,
    showCheckoutOptions: true,
    dataSignIsLive: false, //ONLY IN ELECTRON
  },
  weightLabelSettings: {
    weightSettings : [
      {
        key: 1, 
        active: false,
        prefix: '',
        value: ''
      },
      {
        key: 2, 
        active: false,
        prefix: '',
        value: ''
      },
      {
        key: 3, 
        active: false,
        prefix: '',
        value: '' 
      },
      {
        key: 4, 
        active: false,
        prefix: '',
        value: ''
      }
    ]
  }
  ,
};

export default handleActions(
  {
    PRINT_SETTINGS_SAVE: (state, { payload }) => {
      return {
        ...state,
        printSettings: {
          ...payload,
        },
      };
    },
    GENERAL_SETTINGS_SAVE: (state, { payload }) => {
      return {
        ...state,
        generalSettings: {
          ...payload,
        },
      };
    },
    WEIGHT_LABEL_SETTINGS_SAVE: (state, {payload}) => {
      return {
      ...state,
        weightLabelSettings: {
          ...payload,
        },
      }
    }

  },
  initialState,
);
