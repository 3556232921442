import React, { Component } from 'react';
import Loadable from 'react-loadable';
import AppRoute from 'components/AppRoute';
import { Switch, Route } from 'react-router-dom';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import AppLoader from 'components/AppLoader';

// Load Layouts with code splitting
const PublicLayout = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "PublicLayout" */ 'layouts/PublicLayout/container'
    ),
  loading: AppLoader,
  delay: 300,
});

const DashboardLayout = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "DashboardLayout" */ 'layouts/DashboardLayout/container'
    ),
  loading: AppLoader,
  delay: 300,
});

const AdminLayout = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AdminLayout" */ 'layouts/AdminLayout/container'
    ),
  loading: AppLoader,
  delay: 300,
});

const userIsAuthenticated = connectedRouterRedirect({
  // The url to redirect user to if they fail
  // redirectPath: "/login",
  redirectPath: (state, ownProps) =>
    !state.store.id ? '/login/store' : 'login/user',
  // If selector is true, wrapper will not redirect
  authenticatedSelector: (state) =>
    Boolean(state.store.id && state.user.id && !state.shift.locked),
  allowRedirectBack: false,
  // A nice display name for this check
  wrapperDisplayName: 'UserIsAuthenticated',
  //redirectAction: routerActions.replace,
  // Returns true if the user auth state is loading
  authenticatingSelector: (state) => state.user.loading,
  // Render this component when the authenticatingSelector returns true
  AuthenticatingComponent: AppLoader,
});

// const locationHelper = locationHelperBuilder({});

const userIsNotAuthenticated = connectedRouterRedirect({
  // This sends the user either to the query param route if we have one, or to the landing page if none is specified and the user is already logged in
  // redirectPath: (state, ownProps) => getHomeRoute(state.user),
  redirectPath: (state, ownProps) => '/',
  // locationHelper.getRedirectQueryParam(ownProps) || "/onboarding",
  // This prevents us from adding the query parameter when we send the user away from the login page
  allowRedirectBack: false,
  // If selector is true, wrapper will not redirect
  // So if there is no user data, then we show the page
  authenticatedSelector: (state) =>
    !Boolean(state.store.id) || !Boolean(state.user.id) || state.shift.locked,
  // A nice display name for this check
  wrapperDisplayName: 'UserIsNotAuthenticated',
  // redirectAction: routerActions.replace
});

// Load Routes for code splitting
const DashboardRoute = Loadable({
  loader: () =>
    import(/* webpackChunkName: "DashBoard" */ 'routes/Dashboard/container'),
  loading: AppLoader,
  delay: 300,
});

const InventoryRoute = Loadable({
  loader: () => import('routes/Inventory/container'),
  loading: AppLoader,
  delay: 300,
});

const SettingsRoute = Loadable({
  loader: () => import('routes/Settings/container'),
  loading: AppLoader,
  delay: 300,
});

const ReportsRoute = Loadable({
  loader: () => import('routes/Reports/container'),
  loading: AppLoader,
  delay: 300,
});

const LoginRoute = Loadable({
  loader: () => import('routes/Login/container'),
  loading: AppLoader,
  delay: 300,
});

const StoreLoginRoute = Loadable({
  loader: () => import('routes/StoreLogin/container'),
  loading: AppLoader,
  delay: 300,
});

const NotFound = Loadable({
  loader: () => import(/* webpackChunkName: "NotFound" */ 'routes/NotFound'),
  loading: AppLoader,
  delay: 300,
});

const Notifications = Loadable({
  loader: () => import('routes/Notifications/container'),
  loading: AppLoader,
  delay: 300,
});

class AppRoutes extends Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <AppRoute
            path={'/'}
            exact
            component={userIsAuthenticated(DashboardRoute)}
            layout={DashboardLayout}
          />

          <AppRoute
            path={'/inventory'}
            exact
            component={userIsAuthenticated(InventoryRoute)}
            layout={DashboardLayout}
          />

          <AppRoute
            path={'/settings'}
            exact
            component={userIsAuthenticated(SettingsRoute)}
            layout={AdminLayout}
          />

          <AppRoute
            path={'/reports'}
            exact
            component={userIsAuthenticated(ReportsRoute)}
            layout={AdminLayout}
          />

          <Route
            path={'/login/store'}
            exact
            component={userIsNotAuthenticated(StoreLoginRoute)}
          />

          <Route
            path={'/login/user'}
            exact
            component={userIsNotAuthenticated(LoginRoute)}
          />

          <Route
            path={'/notifications'}
            exact
            component={userIsAuthenticated(Notifications)}
            layout={DashboardLayout}
          />

          <AppRoute component={NotFound} layout={PublicLayout} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default AppRoutes;
