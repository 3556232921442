/**
 * Store
 */
import Database from "./database";
import { apiURL } from "../utils/config";
import axios from "axios";

const loadUrl = apiURL + "/store";

/**
 * Update store
 * @param id
 * @returns {*}
 */
export const getStore = id => {
  return Database.getDB().get(id);
};

/**
 * Update store
 * @param resource
 * @returns {*}
 */
export const updateStore = resource => {
  return Database.getDB()
    .get(resource.id)
    .then(function(doc) {
      return Database.getDB().put({
        _id: resource.id,
        _rev: doc._rev,
        ...resource
      });
    });
};

/**
 * Returns a list of stores (paginated)
 * @returns {AxiosPromise}
 */
export const getIsActiveStore = storeId => {
  return axios({
    url: `${loadUrl}/isStoreActive/${storeId}`,
    method: "GET"
  });
};
