/**
 * App Component
 * Entry point of the app
 * This is where the high level components like redux store, routing, error handling are initialized
 */

import React, { Component } from "react";
import { Provider } from "react-redux";
import { MemoryRouter as Router } from "react-router-dom";
import store, { persistor } from "./store";
import WrapperLayout from "./layouts/WrapperLayout/container";
import "./services/interceptors";
import "normalize.css/normalize.css";
import AppRoutes from "./routes/AppRoutes/container";
import ErrorWrapper from "components/ErrorWrapper";
import { PersistGate } from "redux-persist/integration/react";
import "./styles/index.scss";
import "react-datepicker/dist/react-datepicker.css";
import { setDefaultLocale } from "react-datepicker";
import StoreLoginModal from "./components/StoreLoginModal/container";
setDefaultLocale("el");

class App extends Component {
  componentDidMount() {
    // persistor.purge();
    window.addEventListener("beforeinstallprompt", e => {
      // Stash the event so it can be triggered later.
      // deferredPrompt = e;
      console.log("beforeinstallprompt", e);
    });
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <WrapperLayout>
              <React.Fragment>
                <StoreLoginModal />
                <ErrorWrapper store={store}>
                  <AppRoutes />
                </ErrorWrapper>
              </React.Fragment>
            </WrapperLayout>
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
