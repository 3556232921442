/**
 * Checkout reducer
 * Handles checkout actions
 */

import { createAction, handleActions } from "redux-actions";

// ------------------------------------
// Constants
// ------------------------------------
export const CHECKOUT_SAVE_CURRENT = "CHECKOUT_SAVE_CURRENT";
export const CHECKOUT_CLEAR_CURRENT = "CHECKOUT_CLEAR_CURRENT";
export const CHECKOUT_PARK = "CHECKOUT_PARK";
export const CHECKOUT_PARK_REMOVE = "CHECKOUT_PARK_REMOVE";
export const CHECKOUT_PARK_SAVE_DATA = "CHECKOUT_PARK_SAVE_DATA";

// ------------------------------------
// Actions
// ------------------------------------
const saveCurrentCheckoutData = createAction(CHECKOUT_SAVE_CURRENT);
const clearCurrentCheckoutData = createAction(CHECKOUT_CLEAR_CURRENT);
const parkCheckout = createAction(CHECKOUT_PARK);
const removeFromParked = createAction(CHECKOUT_PARK_REMOVE);
const saveParkedData = createAction(CHECKOUT_PARK_SAVE_DATA);

export const actions = {
  saveCurrentCheckoutData,
  clearCurrentCheckoutData,
  parkCheckout,
  removeFromParked,
  saveParkedData,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  current: { items: [] },
  parked: [],
};

function getParkedCheckoutName(lastParked) {
  const lastParkedName = lastParked.name;

  return "P" + (parseInt(lastParkedName.substr(1)) + 1);
}

export default handleActions(
  {
    CHECKOUT_SAVE_CURRENT: (state, { payload }) => {
      return {
        ...state,
        current: {
          ...state.current,
          items: [].concat(payload),
        },
      };
    },
    CHECKOUT_CLEAR_CURRENT: (state) => {
      return { ...state, current: { items: [] } };
    },
    CHECKOUT_PARK: (state, { payload }) => {
      const parkedName =
        state.parked.length > 0
          ? getParkedCheckoutName(state.parked[state.parked.length - 1])
          : "P1";
      return {
        ...state,
        parked: [
          ...state.parked,
          {
            data: [...payload],
            parkedId: new Date().getTime(),
            name: parkedName,
          },
        ],
      };
    },
    CHECKOUT_PARK_REMOVE: (state, { payload }) => {
      return {
        ...state,
        parked: state.parked.filter((i) => i.parkedId !== payload),
      };
    },
    CHECKOUT_PARK_SAVE_DATA: (state, { payload }) => {

      let craftParked = {data : payload.data, parkedId: payload.parkedId, }

      return {
        ...state,
        parked: state.parked.map((i) => {
          if (i.parkedId === payload.parkedId) {
            return { ...craftParked, name : i.name};
          } else {
            return { ...i };
          }
        }),
      };
    },
  },
  initialState
);
