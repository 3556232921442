let BASE_URL, ENVIRONMENT, SYNC_URL;

export function getConfig(env) {
  let configObj = {
    BASE_URL,
    ENVIRONMENT,
    SYNC_URL
  };

  switch (env) {
    case "development":
      configObj = {
        BASE_URL: process.env.REACT_APP_API_DEV,
        SYNC_URL: process.env.REACT_APP_SYNC_API_DEV,
        ENVIRONMENT: "DEV"
      };
      break;
    case "production":
      configObj = {
        BASE_URL: process.env.REACT_APP_API_PROD,
        SYNC_URL: process.env.REACT_APP_SYNC_API_PROD,
        ENVIRONMENT: "PROD"
      };
      break;
    default:
      configObj = {
        BASE_URL: process.env.REACT_APP_API_DEV,
        SYNC_URL: process.env.REACT_APP_SYNC_API_DEV,
        ENVIRONMENT: "DEV"
      };
      break;
  }

  return configObj;
}

export const baseURL = getConfig(process.env.REACT_APP_API_ENV).BASE_URL;
export const apiURL =
  getConfig(process.env.REACT_APP_API_ENV).BASE_URL + "/api/v1";
export const syncURL = getConfig(process.env.REACT_APP_API_ENV).SYNC_URL;
