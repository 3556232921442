import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'components/Button';
import { Form } from 'react-bootstrap';
import { login } from 'services/session';
import { storeLogin } from 'services/public';
import { toast } from 'react-toastify';
import Database from 'services/database';
import visuals from 'styles/layout/visuals';

export class StoreLoginModal extends React.PureComponent {
  state = { username: null, password: null, loading: false };

  componentDidMount() {
    console.log('StoreLoginModal', this.props);
  }

  saveDataAndContinue = (storeData) => {
    const { saveStoreData, toggleLoginModal } = this.props;
    saveStoreData(storeData);

    Database.syncProducts(() => {
      console.log('products synced');
    });

    Database.sync(storeData.id, () => {
      this.setState({
        loading: false,
      });

      toggleLoginModal(false);
      window.location.reload();
    });
  };

  action = (ev) => {
    ev.preventDefault();
    const { username, password } = this.state;
    this.setState({
      loading: true,
    });

    login(username, password)
      .then((res) => {
        return { username, password };
      })
      .then((data) => {
        return storeLogin(data.username, data.password);
      })
      .then(async (res) => {
        const storeData = res.data.store;

        this.saveDataAndContinue(storeData);
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        toast.error(
          `${
            err && err.response && err.response.data
              ? err.response.data.message || err.response.data.error
              : err
          }`
        );
      });
  };

  render() {
    const { loginModal, toggleLoginModal, mode } = this.props;
    const { username, password, loading } = this.state;
    return (
      <Modal
        show={loginModal}
        onHide={() => toggleLoginModal(false)}
        dialogClassName={
          mode
            ? visuals[mode].modalBgClass + '--centered'
            : 'modal-dialog--full'
        }
        //dialogClassName="modal-dialog--centered"
      >
        <Modal.Header
          closeButton
          style={mode ? { ...visuals[mode].modalHeader } : {}}
        >
          <span className={'text--medium'}>Είσοδος καταστήματος</span>
        </Modal.Header>
        <Modal.Body style={mode ? { ...visuals[mode].modalBody } : {}}>
          <React.Fragment>
            <Form>
              <Form.Group controlId='formBasicEmail'>
                <Form.Control
                  type='text'
                  autoComplete='username'
                  placeholder='Username'
                  onChange={(ev) => {
                    this.setState({
                      username: ev.target.value,
                    });
                  }}
                />
              </Form.Group>

              <Form.Group controlId='formBasicPassword'>
                <Form.Control
                  type='password'
                  placeholder='Password'
                  autoComplete='current-password'
                  onChange={(ev) => {
                    this.setState({
                      password: ev.target.value,
                    });
                  }}
                />
              </Form.Group>
            </Form>
          </React.Fragment>
        </Modal.Body>
        <Modal.Footer
          className={'align--center'}
          style={mode ? { ...visuals[mode].modalFooter } : {}}
        >
          <Button
            variant={'primary'}
            disabled={!username || !password || loading}
            type='button'
            loading={loading}
            onClick={this.action}
            label={'Είσοδος'}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

export default StoreLoginModal;
