/**
 * UserService
 * Handles user related operations
 */
import Database from "./database";
import store from "../store";

/**
 * Login user using username and password
 * @param username
 * @returns {*}
 */
export const loginUser = username => {
  const localStore = store.getState().store;
  return Database.getDB().get(`user::${localStore.id}::${username}`);
};

/**
 * Update user data
 * @param userId
 * @param resource
 * @returns {*}
 */
export const updateUser = (userId, resource) => {
  return Database.getDB()
    .get(userId)
    .then(doc => {
      return doc;
    })
    .then(userData => {
      return Database.getDB().put({ ...userData, ...resource });
    });
};
