/**
 * Shifts
 */
import Database from "./database";
import { resolveConflicts } from "../utils/utils";

/**
 * Load shift by id
 * @param id
 * @returns {*}
 */
export const getShift = id => {
  console.log("docId", id);

  return Database.getDB()
    .get(id)
    .then(doc => {
      return doc;
    })
    .catch(err => {
      if (err && err.status === 404) {
        console.log("Shift not found");
        return new Error("Shift not found.");
      } else {
        return err;
      }
    });
};

export const getOpenShiftsByUser = userId => {
  return Database.getDB()
    .allDocs({
      include_docs: true,
      attachments: false,
      startkey: `shift::${userId}::\ufff0`,
      endkey: `shift::${userId}::`,
      descending: true,
      _conflicts: true
    })
    .then(function(result) {
      return result.rows
        .map(i => {
          //we should resolve any conflicts here
          resolveConflicts(i.doc);

          return i.doc;
        })
        .filter(i => !i.endDate);
    });
};

export const addShift = resource => {
  return Database.getDB().put({
    _id: resource.id,
    ...resource
  });
};

/**
 * Update existing shift
 * @param resource
 * @returns {*}
 */
export const updateShift = resource => {
  return Database.getDB()
    .get(resource.id)
    .then(function(doc) {
      return Database.getDB().put({
        _id: resource.id,
        _rev: doc._rev,
        ...resource
      });
    });
};
