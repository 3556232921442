import axios from "axios";
import { apiURL } from "../utils/config";

/**
 * Returns a list of all vat options
 * @returns {AxiosPromise}
 */
export const userLogin = (username, password) => {
  return axios({
    url: `${apiURL}/auth/login`,
    method: "POST",
    data: { username, password }
  });
};

export const storeLogin = (username, password) => {
  return axios({
    url: `${apiURL}/auth/storeLogin`,
    method: "POST",
    data: { username, password }
  });
};
