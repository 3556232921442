/**
 * Button component
 */
import React from 'react';
import PropTypes from 'prop-types';
import Loader from './Loader/index';

const VARIANT_CLASSES = {
  small: 'btn--small',
  primary: 'btn--primary',
  secondary: 'btn--secondary',
  white: 'btn--white',
  danger: 'btn--danger',
  success: 'btn--success',
  warning: 'btn--warning',
  login: 'btn--login',
};

export const Button = (props) => {
  const {
    className,
    onClick,
    variant,
    loadingType,
    loading,
    disabled,
    fullWidth,
    link,
    Icon,
    outline,
  } = props;
  return (
    <button
      className={
        'btn ' +
        VARIANT_CLASSES[variant] +
        (className ? ' ' + className : '') +
        (fullWidth ? ' btn--fullwidth' : '') +
        (outline ? ' btn--outline' : '') +
        (link ? ' btn--link' : '')
      }
      disabled={disabled}
      onClick={onClick}
    >
      {Icon ? Icon : null}
      {loading ? (
        <Loader
          variant={loadingType}
          showLoader={loading}
          isLogin={variant === 'login'}
        />
      ) : (
        <span>{props.label}</span>
      )}
    </button>
  );
};

Button.defaultProps = {
  variant: 'primary',
  loadingType: 'spinner',
};

Button.propTypes = {
  name: PropTypes.string,
  loadingType: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  outline: PropTypes.bool,
};

export default Button; 
