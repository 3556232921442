/**
 * Terminal
 */
import Database from './database';
import store from '../store';
import axios from 'axios';
import { apiURL } from 'utils/config';

/**
 * Get terminal by id
 * @param terminalId
 * @returns {Function | any | Q.Promise<any> | undefined}
 */
export const getTerminal = (terminalId) => {
  console.log('docId', terminalId);

  return Database.getDB()
    .get(terminalId)
    .then((doc) => {
      return doc;
    })
    .catch((err) => {
      if (err && err.status === 404) {
        console.log('Terminal not found');
        return new Error('Terminal not found.');
      } else {
        return err;
      }
    });
};

/**
 * Get general settings
 */
export const getGeneralSettings = () => {
  const storeId = store.getState().store.id;
  const terminalId = store.getState().terminal.id;
  const docId = `generalSettings::${storeId}::${terminalId}`;
  console.log(docId);
  console.log(Database.getDB().get(docId));
  return Database.getDB().get(docId);
};

/**
 * Get printer settings
 */
export const getTerminalPrintSettings = () => {
  const storeId = store.getState().store.id;
  const terminalId = store.getState().terminal.id;
  const docId = `printSettings::${storeId}::${terminalId}`;
  return Database.getDB().get(docId);
};

/**
 * Update printer settings
 * @param resource
 */
export const updateTerminalPrintSettings = (resource) => {
  const storeId = store.getState().store.id;
  const terminalId = store.getState().terminal.id;
  const docId = `printSettings::${storeId}::${terminalId}`;

  return Database.getDB().upsert(docId, function(doc) {
    return { ...resource, storeId };
  });
};

/**
 * Update general settings
 * @param resource
 */
export const updateGeneralSettings = (resource) => {
  const storeId = store.getState().store.id;
  const terminalId = store.getState().terminal.id;
  const docId = `generalSettings::${storeId}::${terminalId}`;
  return Database.getDB().upsert(docId, function(doc) {
    return { ...resource, storeId };
  });
};

/**
 * Get dark or brand mode settings
 */
export const getAppearanceSettings = () => {
  const storeId = store.getState().store.id;
  const terminalId = store.getState().terminal.id;
  const docId = `appearance::${storeId}::${terminalId}`;
  return Database.getDB().get(docId);
};

/**
 * Update dark or brand mode settings
 * @param resource
 */
export const updateAppearanceSettings = (resource) => {
  const storeId = store.getState().store.id;
  const terminalId = store.getState().terminal.id;
  const docId = `appearance::${storeId}::${terminalId}`;

  return Database.getDB().upsert(docId, function(doc) {
    return { ...resource, storeId };
  });
};

/**
 * Get weight label settings
 */
 export const getWeightLabelSettings = () => {
  const storeId = store.getState().store.id;
  const terminalId = store.getState().terminal.id;
  const docId = `weightLabelSettings::${storeId}::${terminalId}`;
  return Database.getDB().get(docId);
 }

/**
 * Update weight label settings
 * @param resource
 */
 export const updateWeightLabelSettings = (resource) => {
  const storeId = store.getState().store.id;
  const terminalId = store.getState().terminal.id;
  const docId = `weightLabelSettings::${storeId}::${terminalId}`;
  return Database.getDB().upsert(docId, function(doc) {
    return { ...resource, id : docId, storeId };
  });
};

/**
 * Update user data
 * @param id
 * @param resource
 * @returns {*}
 */
export const updateTerminal = (id, resource) => {
  return Database.getDB()
    .get(id)
    .then((doc) => {
      return doc;
    })
    .then((data) => {
      // console.log("DATA", data);
      return Database.getDB().put({ ...data, ...resource });
    });
};

/**
 * Registets a new deviceId to the terminal
 * @param id
 * @param deviceId
 * @returns {AxiosPromise<any>}
 */
export const registerTerminal = (id, deviceId, token) => {
  return axios.put(
    `${apiURL}/store/terminal/${id}/register`,
    {
      deviceId,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const loadTerminal = (id, token) => {
  return axios({
    method: 'GET',
    url: `${apiURL}/store/terminal/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
