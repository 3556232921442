/**
 * Shift reducer
 * Handles user actions
 */

import { createAction, handleActions } from 'redux-actions';
import { addShift, updateShift } from 'services/shift';
import { formatAmount } from 'utils/utils';
import { actions as userActions } from './user';
import { actions as terminalActions } from './terminal';

// ------------------------------------
// Constants
// ------------------------------------
export const SHIFT_START = 'SHIFT_START';
export const SHIFT_END = 'SHIFT_END';
export const SHIFT_ACTION = 'SHIFT_ACTION';
export const SHIFT_LOCK = 'SHIFT_LOCK';
export const SHIFT_UNLOCK = 'SHIFT_UNLOCK';
export const SHIFT_SAVE_DATA = 'SHIFT_SAVE_DATA';
export const KILL_RIGHTS = 'KILL_RIGHTS';
export const ALL_RIGHTS_YES = 'ALL_RIGHTS_YES';
export const ALL_RIGHTS_NO = 'ALL_RIGHTS_NO';

// ------------------------------------
// Actions
// ------------------------------------
const startShiftAction = createAction(SHIFT_START);
const endShiftAction = createAction(SHIFT_END);
const shiftAction = createAction(SHIFT_ACTION);
const lockShift = createAction(SHIFT_LOCK);
const unlockShift = createAction(SHIFT_UNLOCK);
const saveShiftData = createAction(SHIFT_SAVE_DATA);
const killRights = createAction(KILL_RIGHTS);
const allRightsYes = createAction(ALL_RIGHTS_YES);
const allRightsNo = createAction(ALL_RIGHTS_NO);

const startShift = (resource) => {
  const shiftObjId = `shift::${resource.userObj.id}::${
    resource.terminalId
  }::${new Date().getTime()}`;

  resource.id = shiftObjId;
  return (dispatch) => {
    dispatch(userActions.saveShiftId(shiftObjId, resource.userObj.id));
    dispatch(
      terminalActions.saveTerminalShiftData(shiftObjId, resource.userObj.id)
    );
    return dispatch(startShiftAction(resource));
  };
};

const endShift = (resource) => {
  return (dispatch, getState) => {
    const currentShift = { ...getState().shift };
    const userId = getState().user.id;
    const finalShiftObj = {
      ...currentShift,
      ...resource,
      locked: false,
      expectedAmount:
        currentShift.openingAmount +
        currentShift.depositsAmount +
        currentShift.revenuesAmount -
        currentShift.paymentsAmount -
        currentShift.withdrawalsAmount,
      endDate: new Date().getTime(),
      updatedAt: new Date().getTime(),
    };

    // save to pouch
    updateShift(finalShiftObj)
      .then((res) => {
        console.log('Shift saved', res);
        dispatch(terminalActions.clearTerminalShiftData());
        dispatch(userActions.clearShiftId(userId));
        return dispatch(endShiftAction());
      })
      .catch((err) => {
        console.log('error saving shift', err);
        return dispatch(endShiftAction());
      });
  };
};

export const actions = {
  startShift,
  endShift,
  shiftAction,
  lockShift,
  unlockShift,
  saveShiftData,
  killRights,
  allRightsYes,
  allRightsNo,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  locked: false,
  id: null, // eg shift::uuid
  type: 'shift',
  channels: [],
  userObj: null,
  storeId: null,
  terminalId: null,
  startDate: null,
  status: 'active',
  openingAmount: 0,
  revenuesAmount: 0,
  paymentsAmount: 0,
  depositsAmount: 0,
  withdrawalsAmount: 0,
  closingAmount: 0,
  updatedAt: null,
  purchases: [],
  sales: [],
  withdrawals: [],
  deposits: [],
};

export default handleActions(
  {
    SHIFT_START: (state, { payload }) => {
      const shiftObj = {
        ...initialState,
        ...payload,
        terminalId: payload.terminalId,
        channels: [payload.userObj.storeId, payload.userObj.storeGroupId],
        startDate: new Date().getTime(),
        updatedAt: new Date().getTime(),
      };

      addShift(shiftObj)
        .then((res) => {
          console.log('Shift saved', res);
        })
        .catch((err) => {
          console.log('error saving shift', err);
        });

      return {
        ...shiftObj,
      };
    },
    SHIFT_END: (state, { payload }) => {
      // reset state
      return {
        ...initialState,
      };
    },
    SHIFT_SAVE_DATA: (state, { payload }) => {
      return {
        ...payload,
      };
    },
    SHIFT_ACTION: (state, { payload }) => {
      let obj = {};

      switch (payload.actionType) {
        case 'sale':
          obj = {
            ...state,
            updatedAt: new Date().getTime(),
            revenuesAmount:
              formatAmount(state.revenuesAmount) + formatAmount(payload.value),
            sales: [...state.sales, { ...payload }],
          };
          break;
        case 'purchase':
          obj = {
            ...state,
            updatedAt: new Date().getTime(),
            paymentsAmount:
              formatAmount(state.paymentsAmount) + formatAmount(payload.value),
            purchases: [...state.purchases, { ...payload }],
          };
          break;
        case 'withdrawal':
          obj = {
            ...state,
            updatedAt: new Date().getTime(),
            withdrawalsAmount:
              formatAmount(state.withdrawalsAmount) +
              formatAmount(payload.value),
            withdrawals: [...state.withdrawals, { ...payload }],
          };
          break;
        case 'deposit':
          obj = {
            ...state,
            depositsAmount:
              formatAmount(state.depositsAmount) + formatAmount(payload.value),
            updatedAt: new Date().getTime(),
            deposits: [...state.deposits, { ...payload }],
          };
          break;
        default:
          break;
      }

      // save to pouch
      updateShift(obj)
        .then((res) => {
          console.log('Shift saved', res);
        })
        .catch((err) => {
          console.log('error saving shift', err);
        });

      return obj;
    },
    SHIFT_LOCK: (state, { payload }) => {
      return {
        ...state,
        locked: true,
      };
    },
    SHIFT_UNLOCK: (state, { payload }) => {
      return {
        ...state,
        locked: false,
      };
    },
    KILL_RIGHTS: (state, { payload }) => {
      return {
        ...state,
        userObj: { ...state.userObj, userRights: undefined },
      };
    },
    ALL_RIGHTS_YES: (state, { payload }) => {
      return {
        ...state,
        userObj: {
          ...state.userObj,
          userRights: {
            rightsAdmin: {
              account: {
                shifts: true,
              },
              reports: {
                sales: true,
                warehouse: true,
              },
              sales: {
                invoices: true,
                receipts: true,
              },
              settings: {
                cashRegisterParts: true,
                general: true,
                tabs: true,
                topup: true,
                users: true,
              },
              traders: {
                customers: true,
                suppliers: true,
              },
              warehouse: {
                categories: true,
                discountPolices: true,
                products: true,
                purchases: true,
              },
            },
            rightsPOS: {
              allowDelayPayment: true,
              changeProductPrice: true,
              createCustomer: true,
              createProduct: true,
              grantDiscount: true,
              inventoryManage: true,
              manualReciept: true,
              quickProductEdit: true,
              showSalesTotal: true,
              withdrawRecieve: true,
            },
          },
        },
      };
    },
    ALL_RIGHTS_NO: (state, { payload }) => {
      return {
        ...state,
        userObj: {
          ...state.userObj,
          userRights: {
            rightsAdmin: {
              account: {
                shifts: false,
              },
              reports: {
                sales: false,
                warehouse: false,
              },
              sales: {
                invoices: false,
                receipts: false,
              },
              settings: {
                cashRegisterParts: false,
                general: false,
                tabs: false,
                topup: false,
                users: false,
              },
              traders: {
                customers: false,
                suppliers: false,
              },
              warehouse: {
                categories: false,
                discountPolices: false,
                products: false,
                purchases: false,
              },
            },
            rightsPOS: {
              allowDelayPayment: false,
              changeProductPrice: false,
              createCustomer: false,
              createProduct: false,
              grantDiscount: false,
              inventoryManage: false,
              manualReciept: false,
              quickProductEdit: false,
              showSalesTotal: false,
              withdrawRecieve: false,
            },
          },
        },
      };
    },
  },
  initialState
);
