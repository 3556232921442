/**
 * Terminal reducer
 */

import { createAction, handleActions } from "redux-actions";
import { updateTerminal } from "services/terminal";

// ------------------------------------
// Constants
// ------------------------------------
export const TERMINAL_SAVE = "TERMINAL_SAVE";
export const TERMINAL_SAVE_SHIFT_DATA = "TERMINAL_SAVE_SHIFT_DATA";
export const TERMINAL_CLEAR_SHIFT_DATA = "TERMINAL_CLEAR_SHIFT_DATA";

// ------------------------------------
// Actions
// ------------------------------------
const saveTerminalAction = createAction(TERMINAL_SAVE);
const saveTerminalShiftDataAction = createAction(TERMINAL_SAVE_SHIFT_DATA);
const clearTerminalShiftDataAction = createAction(TERMINAL_CLEAR_SHIFT_DATA);

const saveTerminalShiftData = (shiftId, userId) => {
  return (dispatch, getState) => {
    const terminalId = getState().terminal.id;
    updateTerminal(terminalId, {
      currentShiftId: shiftId,
      currentShiftUser: userId
    })
      .then(() => {
        return dispatch(
          saveTerminalShiftDataAction({
            currentShiftId: shiftId,
            currentShiftUser: userId
          })
        );
      })
      .catch(err => {
        console.log(err);
        if (err.name === "conflict") {
          // conflict!
          console.log("conflict", err);
        } else {
          return dispatch(clearTerminalShiftDataAction());
        }
      });
  };
};

const clearTerminalShiftData = () => {
  return (dispatch, getState) => {
    const terminalId = getState().terminal.id;
    updateTerminal(terminalId, { currentShiftId: null, currentShiftUser: null })
      .then(() => {
        return dispatch(clearTerminalShiftDataAction());
      })
      .catch(err => {
        console.log(err);
        return dispatch(clearTerminalShiftDataAction());
      });
  };
}; /*const saveTerminalId = () => {
  return dispatch => {
    //return dispatch(saveTerminalIdAction(true));

    if (window.requestIdleCallback) {
      requestIdleCallback(function() {
        getFingerPrint(id => dispatch(saveTerminalIdAction(id)));
      });
    } else {
      setTimeout(function() {
        getFingerPrint(id => dispatch(saveTerminalIdAction(id)));
      }, 500);
    }
  };
};*/

/*function getFingerPrint(callcack) {
  Fingerprint2.get(function(components) {
    console.log(components);
    let values = components.map(function(component) {
      return component.value;
    });
    let murmur = Fingerprint2.x64hash128(values.join(""), 31);
    console.log("murmur", murmur);
    if (murmur && callcack) callcack(murmur);
    return murmur;
  });
}*/ /*const saveTerminalId = () => {
  return dispatch => {
    //return dispatch(saveTerminalIdAction(true));

    if (window.requestIdleCallback) {
      requestIdleCallback(function() {
        getFingerPrint(id => dispatch(saveTerminalIdAction(id)));
      });
    } else {
      setTimeout(function() {
        getFingerPrint(id => dispatch(saveTerminalIdAction(id)));
      }, 500);
    }
  };
};*/ export const actions = {
  saveTerminalAction,
  saveTerminalShiftData,
  clearTerminalShiftData
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  id: null,
  deviceId: null,
  name: null,
  updatedAt: null,
  currentShiftId: null,
  currentShiftUser: null
};

export default handleActions(
  {
    TERMINAL_SAVE: (state, { payload }) => {
      return {
        ...payload
      };
    },
    TERMINAL_SAVE_SHIFT_DATA: (state, { payload }) => {
      return {
        ...state,
        currentShiftId: payload.shiftId,
        currentShiftUser: payload.userId
      };
    },
    TERMINAL_CLEAR_SHIFT_DATA: state => {
      return { ...state, currentShiftId: null, currentShiftUser: null };
    }
  },
  initialState
);
