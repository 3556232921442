/**
 * User reducer
 * Handles user actions
 */

import { createAction, handleActions } from "redux-actions";
import { updateUser } from "services/user";

// ------------------------------------
// Constants
// ------------------------------------
export const USER_DATA_SAVE = "USER_DATA_SAVE";
export const USER_CLEAR = "USER_CLEAR";
export const USER_SAVE_SHIFT_ID = "USER_SAVE_SHIFT_ID";
export const USER_CLEAR_SHIFT_ID = "USER_CLEAR_SHIFT_ID";

// ------------------------------------
// Actions
// ------------------------------------
const clearUser = createAction(USER_CLEAR);
const saveUserData = createAction(USER_DATA_SAVE);
const saveShiftIdAction = createAction(USER_SAVE_SHIFT_ID);
const clearShiftIdAction = createAction(USER_CLEAR_SHIFT_ID);

const saveShiftId = (shiftId, userId) => {
  return (dispatch, getState) => {
    console.log("Will update user", getState().user);

    updateUser(userId, { currentShiftId: shiftId })
      .then(() => {
        return dispatch(saveShiftIdAction(shiftId));
      })
      .catch(err => {
        console.log("error updating user");
        console.log(err);
        return dispatch(clearShiftIdAction());
      });
  };
};

const clearShiftId = userId => {
  return dispatch => {
    updateUser(userId, { currentShiftId: null })
      .then(() => {
        return dispatch(clearShiftIdAction());
      })
      .catch(err => {
        console.log(err);
        return dispatch(clearShiftIdAction());
      });
  };
};

/**
 * Logout
 * @returns {function(*): *}
 */
const logout = () => {
  return dispatch => {
    return dispatch(clearUser(true));
  };
};

export const actions = {
  saveUserData,
  clearUser,
  saveShiftId,
  clearShiftId,
  logout
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  id: null,
  currentShiftId: null
};

export default handleActions(
  {
    LOGOUT_SUCCESS: () => {
      return { ...initialState };
    },
    USER_DATA_SAVE: (state, { payload }) => {
      return { ...state, ...payload };
    },
    USER_SAVE_SHIFT_ID: (state, { payload }) => {
      return { ...state, currentShiftId: payload };
    },
    USER_CLEAR_SHIFT_ID: state => {
      return { ...state, currentShiftId: null };
    },

    USER_CLEAR: () => {
      return { ...initialState };
    }
  },
  initialState
);
