import PouchDB from "pouchdb-browser";
//import PouchdbFind from "pouchdb-find";
//import PouchdbQuickSearch from "pouchdb-quick-search";
import store from "../store";
import { syncURL } from "utils/config";
import { retryUntilWritten } from "../utils/utils";
import { actions as storeActions } from "../modules/store";

//PouchDB.plugin(PouchdbFind);
//PouchDB.plugin(PouchdbQuickSearch);
PouchDB.plugin(require("pouchdb-upsert"));

const replicationOptions = {
  live: false,
  retry: false,
  include_docs: true,
  crossDomain: true,
  attachments: true,
  batch_size: 20
};

export const Database = () => {
  const DB_URL = `${syncURL}/netlinkdev`;
  const DB_PRODUCTS_URL = `${syncURL}/netlinkproducts`;
  let db = null;
  let productsDb = null;

  const remoteDB = new PouchDB(DB_URL, {
    fetch(url, opts) {
      opts.credentials = "include";
      opts.include_docs = true;
      opts.crossDomain = true;
      opts.withCredentials = true;
      opts.skip_setup = true;
      return PouchDB.fetch(url, opts);
    }
  });

  const remoteProductsDB = new PouchDB(DB_PRODUCTS_URL, {
    fetch(url, opts) {
      opts.credentials = "include";
      opts.include_docs = true;
      opts.crossDomain = true;
      opts.withCredentials = true;
      opts.skip_setup = true;
      return PouchDB.fetch(url, opts);
    }
  });

  function sync(storeId, callback, options) {
    if (!storeId) {
      storeId = store.getState().store.id;
    }

    console.log("sync: ", storeId);

    db = new PouchDB(`netlinkdev_${process.env.REACT_APP_API_ENV}_${storeId}`, {
      revs_limit: 500,
      batch_size: 20,
      auto_compaction: true
    });

    db.sync(remoteDB, options || replicationOptions)
      .on("change", function(info) {
        // handle change
        console.log("on change", info);
      })
      .on("paused", function(err) {
        // replication paused (e.g. replication up to date, user went offline)
        console.log("paused", err);
      })
      .on("active", function() {
        console.log("active");
        // replicate resumed (e.g. new changes replicating, user went back online)
      })
      .on("denied", function(err) {
        console.log("denied", err);
        // a document failed to replicate (e.g. due to permissions)
      })
      .on("complete", function(info) {
        console.log("complete", info);
        if (callback) callback();
        // complete event only fires if the live option is false, so after the complete event
        // we call the sync function again with live set to true
        sync(storeId, null, {
          ...replicationOptions,
          live: true,
          retry: true,
          continuous: true
        });
      })
      .on("error", function(err) {
        console.log("error 2", err);

        /* if (callback) callback();

        sync(storeId, null, {
          ...replicationOptions,
          live: true,
          retry: true,
          continuous: true
        });*/

        // here there is no specific error. It can happen when the SG responds with network fail or connection reset messages
        // we should allow the user to proceed and sync at a later time
        if (err.result && err.result.errors && err.result.errors.length === 0) {
          console.log("no specific error login");
          if (callback) callback();
        }

        // The error in this case is that the session of the user has expired. We need to display a modal to let them reconnect
        if (err.name && err.name.toLowerCase() === "unauthorized") {
          console.log("I should login as store again");
          store.dispatch(storeActions.toggleLoginModal(true));
        }

        // Some times there is a conflict error from SG
        // we should allow the user to proceed and sync at a later time
        if (err.result.errors && err.result.errors.length > 0) {
          for (let i = 0; i < err.result.errors.length; i++) {
            let error = err.result.errors[i];

            if (error.status === 409) {
              console.log("There is a conflict");
              getDB()
                .get(error.id)
                .then(doc => {
                  console.log("doc", doc);
                  retryUntilWritten(doc);
                })
                .then(res => {
                  console.log("retryUntilWritten RES", res);
                });
            }

            if (callback) callback();
          }

          /*if (callback) callback();

          sync(storeId, null, {
            ...replicationOptions,
            live: true,
            retry: true,
            continuous: true
          });*/
        }
      });
    return db;
  }

  function syncProducts(callback) {
    console.log("sync: netlink_products");

    productsDb = new PouchDB(
      `netlink_products_${process.env.REACT_APP_API_ENV}`,
      {
        // revs_limit: 10,
        auto_compaction: true
      }
    );

    productsDb.replicate
      .from(remoteProductsDB, {
        live: false,
        retry: false,
        include_docs: true,
        crossDomain: true,
        attachments: false,
        batch_size: 20
      })
      .then(function(result) {
        console.log("result", result);
        // handle 'completed' result
      })
      .catch(function(err) {
        console.log(err);
      });

    /*    productsDb
      .createIndex({
        index: { fields: ["barcode", "active"] },
        ddoc: "idx-products-product"
      })
      .then(res => {
        //console.log("index created ", res);
      })
      .catch(err => {
        console.log("error creating index ", err);
      });

    productsDb
      .createIndex({
        index: { fields: ["barcode", "name"] },
        ddoc: "idx-products-product2"
      })
      .then(res => {
        //console.log("index created ", res);
      })
      .catch(err => {
        console.log("error creating index ", err);
      });*/

    return productsDb;
  }

  function getDB() {
    if (db) {
      return db;
    } else {
      sync(store.getState().store.id);
      return new PouchDB(
        `netlinkdev_${process.env.REACT_APP_API_ENV}_${
          store.getState().store.id
        }`
      );
    }
  }

  function getProductsDB() {
    if (productsDb) {
      return productsDb;
    } else {
      syncProducts();
      return new PouchDB(`netlink_products_${process.env.REACT_APP_API_ENV}`);
    }
  }

  function getStoreDoc(storeID) {
    return new Promise(function(resolve, reject) {
      getDB().get(storeID, function(err, doc) {
        if (err) {
          reject({
            ...err,
            details: `error getting store doc`
          });
        }

        if (doc) {
          let currentStoreDoc = doc;
          resolve(currentStoreDoc);
        }
      });
    });
  }

  return {
    getDB,
    getProductsDB,
    sync,
    syncProducts,
    getStoreDoc
  };
};

export default Database();
