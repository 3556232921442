// import { actions as accountActions } from ' redux/modules/account'
import AuthService from "./auth";
import axios from "axios";
// import { browserHistory } from "react-router";

const clearAuth = () => {
  AuthService.clearAuthInfo();
  setTimeout(() => {
    // browserHistory.push("/");
  }, 100);
};

const checkTokenActions = (error, resolve, reject) => {
  let objToReplay = {
    initialRequest: error.config,
    resolve: resolve,
    reject: reject
  };

  AuthService.pushToQueue(objToReplay);

  if (!AuthService.firstTimeFail) {
    AuthService.firstTimeFail = true;
    AuthService.refreshToken().then(
      function(response) {
        AuthService.setAuthInfo(response.data);
        setTimeout(() => {
          AuthService.replayQueuedRequests();
        }, 200);
      },
      () => {
        clearAuth();
      }
    );
  }
};

// Handle API request errors
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return new Promise((resolve, reject) => {
      if (error && !error.response) {
        // Alert.error("Some error happened, please try again later");
      } else if (
        error.response.status === 401 &&
        AuthService.isSavedTokenExpired()
      ) {
        checkTokenActions(error, resolve, reject);
      } else if (error.response.status === 401) {
        if (AuthService.loggedIn()) {
          clearAuth();
        } else {
          reject(error);
          // .push("/");
        }
      } else {
        // Alert.error(error.response.data.message);

        if (error.config.clearCredentialsOnError) {
          clearAuth();
        }

        reject(error);
      }
    });
  }
);
