// Ultraviolet

export const visuals = {
  light: {
    base: {},
    element: {},
    container: {},
    product: {},
    textOnly: {},
    textReinforce: {},
    field: {},

    //Modals
    modalHeader: {},
    modalBody: {},
    modalAdvanced: {},
    modalFooter: {},
    modalBgClass: "modal-dialog"
  },
  dark: {
    base: {},
    element: {},
    container: {},
    product: {},
    textOnly: {},
    textReinforce: {},
    field: {},

    //Modals
    modalHeader: {},
    modalBody: {},
    modalAdvanced: {},
    modalFooter: {},
    modalBgClass: "modal-dialog"
  },
  cerulian: {
    base: {},
    element: {},
    container: {},
    product: {},
    textOnly: {},
    textReinforce: {},
    field: {},

    //Modals
    modalHeader: {},
    modalBody: {},
    modalAdvanced: {},
    modalFooter: {},
    modalBgClass: "modal-dialog"
  },
  ultraviolet: {
    base: { backgroundColor: "#1c1d31", color: "#FFFFFF" },
    element: { backgroundColor: "#21253D", color: "#FFFFFF" },
    container: { backgroundColor: "#2C3252", color: "#FFFFFF" },
    product: {
      border: "solid 2px #2C3252",
      backgroundImage: "linear-gradient(to bottom, #2C3252, #2C3252)",
    },
    textOnly: {
      color: "#FFFFFF",
    },
    textReinforce: { color: "#000000" },
    field: { backgroundColor: "#21253D", color: "#FFFFFF" },

    //Modals
    modalHeader: {
      backgroundColor: "#21253D",
      borderBottom: "1px solid #4A5990",
      color: "#FFFFFF",
    },
    modalBody: {
      backgroundColor: "#21253D",
      color: "#FFFFFF",
    },
    modalAdvanced: {
      // for use only in CheckoutConfirm Modal - Advanced Purchase Options
      backgroundColor: "#21253D",
      borderRadius: "0px",
      borderTop: "1px solid #4A5990",
      color: "#FFFFFF",
    },
    modalFooter: {
      backgroundColor: "#21253D",
      borderTop: "1px solid #4A5990",
      color: "#FFFFFF",
    },
    modalBgClass: "modal-dialog-ultraviolet"
  },
  midnight: {
    base: { backgroundColor: "#121212", color: "#FFFFFF" },
    element: { backgroundColor: "#242424", color: "#FFFFFF" },
    container: { backgroundColor: "#363636", color: "#FFFFFF" },
    product: {
      border: "solid 2px #464646",
      backgroundImage: "linear-gradient(to bottom, #363636, #242424)",
    },
    textOnly: {
      color: "#FFFFFF",
    },
    textReinforce: { color: "#000000" },

    field: { backgroundColor: "#121212", color: "#FFFFFF" },

    //Modals
    modalHeader: {
      backgroundColor: "#242424",
      borderBottom: "1px solid #484848",
      color: "#FFFFFF",
    },
    modalBody: {
      backgroundColor: "#242424",
      color: "#FFFFFF",
    },
    modalAdvanced: {
      // for use only in CheckoutConfirm Modal - Advanced Purchase Options
      backgroundColor: "#242424",
      borderRadius: "0px",
      borderTop: "1px solid #484848",
      color: "#FFFFFF",
    },
    modalFooter: {
      backgroundColor: "#242424",
      borderTop: "1px solid #484848",
      color: "#FFFFFF",
    },
    modalBgClass: "modal-dialog-midnight"
  },
};

export default visuals;
