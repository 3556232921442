import axios from "axios";
import { syncURL } from "utils/config";

export const login = (username, password) => {
  return axios
    .post(
      `${syncURL}/netlinkdev/_session`,
      {
        name: username,
        password
      },
      { withCredentials: true }
    )
    .then(res => {
      return axios.post(
        `${syncURL}/netlinkproducts/_session`,
        {
          name: username,
          password
        },
        { withCredentials: true }
      );
    })
    .catch(err => {
      console.log(err);
    });
};
