import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as storeActions } from "modules/store";
import View from "./";

const mapStateToProps = ({ store, appearance }) => {
  return {
    loginModal: store.loginModal,
    mode: appearance.mode
  };
};

const mapDispatchToProps = dispatch => {
  const actions = {
    saveStoreData: storeActions.saveStoreData,
    toggleLoginModal: storeActions.toggleLoginModal
  };

  return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
