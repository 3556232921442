/**
 * AuthService
 * Handles authentication
 */

import { baseURL } from "../utils/config";
import CookieHelper from "../utils/cookie";
import axios from "axios";
// import moment from "moment";
import { addSeconds, compareAsc, parse } from "date-fns";

class AuthService {
  loginUrl = baseURL + "/oauth/token";
  logoutUrl = baseURL + "/oauth/access-token";

  authObj = {
    isLoggedIn: false,
    access_token: null,
    token_type: null,
    refresh_token: null,
    expires_in: null,
    scope: ""
  };

  roles = [];
  firstTimeFail = false;
  requestsQueue = [];

  defaultHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
    //Authorization: authKey,
    "X-Client": "WEB"
  };

  isSavedTokenExpired = (authInfo = JSON.parse(CookieHelper.load("auth"))) => {
    let hasExpired = false;
    let now = new Date();

    if (
      authInfo &&
      authInfo.expiresOn &&
      compareAsc(now, new Date(authInfo.expiresOn)) === 1
    ) {
      console.log("token has expired. i will refresh it");
      hasExpired = true;
    }

    return hasExpired;
  };

  loggedIn = (authInfo = CookieHelper.load("auth")) => {
    let isLoggedIn = false;
    if (authInfo) {
      isLoggedIn = JSON.parse(authInfo).isLoggedIn;
    }
    return isLoggedIn;
  };

  setAuthInfo = authObj => {
    authObj.isLoggedIn = true;
    authObj.expiresOn = parse(addSeconds(new Date(), authObj.expires_in));
    this.authObj = authObj;
    CookieHelper.save("auth", JSON.stringify(authObj));
  };

  clearAuthInfo = () => {
    this.authObj = {};
    this.roles = [];
    CookieHelper.remove("auth");
    CookieHelper.remove("roles");
  };

  getAuthorizationHeaders = (authInfo = CookieHelper.load("auth")) => {
    let authHeaderValue = null;

    if (authInfo) {
      authHeaderValue = JSON.parse(authInfo).access_token;
    }

    return Object.assign({}, this.getDefaultHeaders(), {
      Authorization: "Bearer " + authHeaderValue
    });
  };

  getDefaultHeaders = () => {
    return this.defaultHeaders;
  };

  pushToQueue = promise => {
    this.requestsQueue.push(promise);
  };

  clearQueue = () => {
    this.requestsQueue = [];
  };

  replayQueuedRequests = () => {
    let req = this.requestsQueue;
    for (let i = 0; i < req.length; i++) {
      req[i].initialRequest.headers = this.getAuthorizationHeaders();
      // Replay request
      axios(req[i].initialRequest)
        .then(response => {
          req[i].resolve(response);
        })
        .catch(response => {
          req[i].reject(response);
        });
    }

    this.clearQueue();
    this.firstTimeFail = false;
  };
}

export default new AuthService();
