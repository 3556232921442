import { actions as processActions } from "modules/process";
import uuidv4 from "uuid/v4";
import { getStore } from "../services/store";
import store from "../store";
import { actions as storeActions } from "modules/store";
import { toast } from "react-toastify";
import Database from "../services/database";
import Fingerprint2 from "fingerprintjs2";

const sizes = {
  xs: 320,
  sm: 600,
  md: 1000,
  lg: 1200,
  xl: 1500,
};
export const emitProcess = (processName, processType, msg) => {
  switch (processName) {
    case "loading":
      return processActions.processLoading({
        type: processType,
        message: msg,
      });
    case "success":
      return processActions.processSuccess({
        type: processType,
        message: msg,
      });
    case "error":
      return processActions.processFailure({
        type: processType,
        message: msg,
      });
    default:
      return processActions.processLoading({
        type: processType,
        message: msg,
      });
  }
};

/**
 * Gets the url parameter my name
 * @param name
 * @param url
 * @returns {*}
 */
export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, "\\$&");
  let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

/**
 * Console log only in non production mode
 * @param msg
 */
export const logToConsole = (msg) => {
  if (process.env.REACT_APP_API_ENV !== "production") {
    console.log(msg);
  }
};

/**
 * Gets the current viewport size (xs, sm, md, lg etc)
 * @returns {string}
 */
export function getCurrentSize() {
  let d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0];

  const windowWidth = window.innerWidth || e.clientWidth || g.clientWidth;

  for (let i in sizes) {
    if (windowWidth <= sizes[i]) return i;
  }
}

/**
 * Format a string or a number to the right number format
 * @param price
 * @returns {number}
 */
export function formatAmount(price) {
  if (price === null) return 0;

  if (price.toString().indexOf(",") > -1)
    price = price.toString().replace(",", ".");

  return Number(parseFloat(price).toFixed(2));
}

/**
 * Format price with sign
 * @param price
 * @returns {string}
 */
export function formatPriceWithSign(price) {
  return formatAmount(price) + " €";
}

export function numWithDecimals(str) {
  if (!str) return 0;

  return Number(parseFloat(str).toFixed(2));
}

export function isMobile() {
  return Boolean(
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent
    ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        navigator.userAgent.substr(0, 4)
      )
  );
}

export function formatNumInput(str) {
  return str.replace(/[^0-9.]/g, "");
}

export function createId(type) {
  return type + "::" + uuidv4();
}

export const checkStoreForTopUpSettings = async () => {
  let localStore;
  try {
    localStore = await getStore(`${store.getState().store.id}`);
    store.dispatch(storeActions.saveStoreData(localStore));
    if (
      !localStore.topUp ||
      (localStore.topUp &&
        (!localStore.topUp.password || !localStore.topUp.username))
    ) {
      toast.error(
        "Δεν εισάγει τις ρυθμίσεις top up! Παρακαλώ μπείτε στο Admin διαχειριστικό και συμπληρωστε username/password από το μενού Ρυθμίσεις --> Top Up"
      );

      return false;
    }
    return {
      ...localStore.topUp,
    };
  } catch (e) {
    toast.error("Σφάλμα κατα την φόρτωση του καταστήματος");
    console.log(e);
    return false;
  }
};

export const uniqueProductsByNameArray = (arr) =>
  arr.filter((prod, index) => {
    const firstIndexOfElement = arr.findIndex((obj) => {
      return obj.doc.name === prod.doc.name;
    });
    return index === firstIndexOfElement || firstIndexOfElement === -1;
  });

/**
 * resolveConflicts
 * let couchbase decide the correct version by discarding the other revisions
 * @param doc
 */
export function resolveConflicts(doc) {
  if (doc._conflicts) {
    for (let c = 0; c < doc._conflicts.length; c++) {
      Database.getDB()
        .remove(doc.id, doc._conflicts[c])
        .then(() => {
          console.log(doc._conflicts[c], "deleted");
        })
        .catch((err) => {
          console.log(doc._conflicts[c], "deletion error ", err);
        });
    }
  }
}

/** Resolve conflicts when updating docs
 * @param doc
 * @returns {Function | any | Q.Promise<any> | undefined}
 */
export async function retryUntilWritten(doc) {
  return Database.getDB()
    .get(doc._id)
    .then(function(origDoc) {
      doc._rev = origDoc._rev;
      console.log("retryUntilWritten", doc);
      return Database.getDB(doc);
    })
    .catch(function(err) {
      console.log("retryUntilWritten err", err);
      if (err.status === 409) {
        return retryUntilWritten(doc);
      } else {
        // new doc
        return Database.getDB(doc);
      }
    });
}

export function getFingerPrint() {
  return new Promise(function(resolve, reject) {
    if (window.requestIdleCallback) {
      requestIdleCallback(function() {
        Fingerprint2.get(function(components) {
          //console.log(components);
          let values = components.map(function(component) {
            return component.value;
          });
          let murmur = Fingerprint2.x64hash128(values.join(""), 31);
          resolve({ deviceId: murmur });
        });
      });
    } else {
      setTimeout(function() {
        Fingerprint2.get(function(components) {
          //console.log(components);
          let values = components.map(function(component) {
            return component.value;
          });
          let murmur = Fingerprint2.x64hash128(values.join(""), 31);
          resolve({ deviceId: murmur });
        });
      }, 500);
    }
  });
}

/**
 * Check if app is running inside an electron window
 * @returns {boolean}
 */
export const isElectron = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return Boolean(userAgent.indexOf(" electron/") > -1);
};

/**
 * Sync database
 */
export const syncDatabase = () => {
  const storeId = store.getState().store.id;
  if (!storeId) return;

  Database.sync(storeId, () => {
    console.log(`store ${storeId} synced`);
  });
};
