/**
 * Combine reducers
 */

import { combineReducers } from "redux";
import account from "./account";
import checkout from "./checkout";
import process from "./process";
import nav from "./nav";
import shift from "./shift";
import settings from "./settings";
import store from "./store";
import terminal from "./terminal";
import user from "./user";
import appearance from "./appearance"

export default combineReducers({
  account,
  checkout,
  nav,
  process,
  settings,
  shift,
  store,
  terminal,
  user,
  appearance
});
