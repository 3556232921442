/**
 * Wrapper layout for the app
 * Handles the display of the app messages returned from async operations through the Process component
 * Checks if the user exists or needs to be reloaded
 */

import React from 'react';
import PropTypes from 'prop-types';
// import AuthService from "services/auth";
import Process from 'components/Process';
import visuals from 'styles/layout/visuals';

export class WrapperLayout extends React.Component {
  /*componentDidMount() {
    const { user, getUser, clearUser } = this.props;
    const isLoggedIn = AuthService.loggedIn();

    if (!user.id && isLoggedIn) {
      getUser();
    } else if (!user.id && !isLoggedIn) {
      clearUser();
    }
  }*/

  render() {
    const { children, process, mode } = this.props;

    document.body.style.backgroundColor =
      mode && visuals[mode].base.backgroundColor
        ? visuals[mode].base.backgroundColor
        : '';

    return (
      <div
        className={'height--full--scroll'}
        style={mode ? { ...visuals[mode].textOnly } : {}}
      >
        {children}
        <Process process={process} />
      </div>
    );
  }
}

WrapperLayout.propTypes = {
  children: PropTypes.node,
};

export default WrapperLayout;
