/**
 * Store reducer
 * Handles login/logout actions
 */

import { createAction, handleActions } from "redux-actions";

// ------------------------------------
// Constants
// ------------------------------------
export const STORE_DATA_SAVE = "STORE_DATA_SAVE";
export const STORE_CLEAR = "STORE_CLEAR";
export const STORE_TOGGLE_LOGIN = "STORE_TOGGLE_LOGIN";

// ------------------------------------
// Actions
// ------------------------------------
const saveStoreData = createAction(STORE_DATA_SAVE);
const clearStore = createAction(STORE_CLEAR);
const toggleLoginModal = createAction(STORE_TOGGLE_LOGIN);

export const actions = {
  saveStoreData,
  clearStore,
  toggleLoginModal
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  id: null,
  loginModal: false
};

export default handleActions(
  {
    STORE_DATA_SAVE: (state, { payload }) => {
      return {
        ...state,
        ...payload
      };
    },
    STORE_CLEAR: () => {
      return {
        ...initialState
      };
    },
    STORE_TOGGLE_LOGIN: (state, { payload }) => {
      console.log("STORE_TOGGLE_LOGIN", payload);
      return {
        ...state,
        loginModal: payload
      };
    }
  },
  initialState
);
